<template>
  <div class="neiBg">
	   <InPageImage></InPageImage>
    <div class="wrapper baseContent">
      <Breadcrumb :crumbsList="pathList"></Breadcrumb>
    </div>
    <div class="wrapper bg-white">
		<div class="newsMenu flex align-center justify-center">
			<div @click="goOne('20230205235228077762721476772968')" :class="kindId=='20230205235228077762721476772968'?'newsMenuItem checked':'newsMenuItem'">公司简介</div>
			<!-- <div @click="goOne('20230725092250083298743993060466')" :class="kindId=='20230725092250083298743993060466'?'newsMenuItem checked':'newsMenuItem'">企业文化</div> -->
			<div @click="goTwo('20230205235257041709876461676320')" class="newsMenuItem">企业荣誉</div>
		</div>
      <div style="padding:15px;box-sizing: border-box;">
        <div class=" flex justify-between ">
          <div class="neiRightBox">
            <div style="padding-left:15px;box-sizing: border-box;">
              <div class="base-font-26 text-bold text-center padding-top-10 padding-bottom-10">{{ title }}</div>
              <div v-html="content" class="base-font-16 margin-top-10" style="line-height:2.1;">
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
import Breadcrumb from '../../components/breadcrumbs.vue'
import hotNews from '../news/hotNews'
import InPageImage from '../../components/inPageImage.vue'
import {getOneWzContent, menuByUrl} from "@/api/api";

export default {
  components: {
    Breadcrumb,
    hotNews,
	InPageImage
  },
  data() {
    return {
      title: '',
      kindId:'',
      content: '',
      leftMenu: {childList: []},
      pathList: [],
      newsList: []
    }
  },
  mounted() {
    var kindId = this.$route.query.kindId;
    var path = this.$route.path + "?kindId=" + kindId;
    this.kindId=kindId;
    this.getContent(kindId);
    this.menuByUrl(path);
  },
  methods: {
    getContent(kindId) {
      getOneWzContent(kindId).then(res => {
        this.title = res.data.title;
        this.content = res.data.content;
      })
    },
    menuByUrl(path) {
      menuByUrl(1, path).then(res => {
        this.pathList = res.data.pathList;
        this.leftMenu = res.data.leftMenu;
		console.log( this.pathList,' this.pathList')
      })
    },
    goNewsDetails(id) {
      this.$router.push({path: '/neswDetails?id=' + id});
    },
    goOne(kindId) {
      this.kindId=kindId;
      this.getContent(kindId);
    },
    goTwo(kindId) {
      this.$router.push({path: '/casesList?kindId=' + kindId});
    }
  }
}
</script>

<style>
	.newsMenu{padding:15px 0;}
	.newsMenuItem{width:180px;height:48px; border:1px solid #dddddd;margin:30px 10px;text-align: center;line-height:50px;font-size:15px;font-size:16px;cursor: pointer;font-weight:bold;}
	.newsMenuItem.checked,.newsMenuItem:hover{border:1px solid #253783;background:#253783;color:#ffffff;}
.neiBg {

  background-size: cover;
  position: relative;
}

.neiLeftMenu ul li {
  width: 100%;
  height: 55px;
  line-height: 55px;
  background: #f8f8f8;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
}

.neiLeftMenu ul li a {
  text-decoration: none;
  color: #4f5458;
}

.neiLeftMenu ul li:hover {
  font-weight: bold;
  color: #2d8cdb;
}

.neiLeftMenu ul li + li {
  border-top: 1px dashed #dddddd;
}

.neiLeftbox {
  width: 250px;
  box-shadow: 0 0 5px #dddddd;
  min-height: 100%;
  padding: 0;
  margin: 0;;
}

.neiLeftbox .neiBaseTit {
  width: 100%;
  height: auto;
  background: #2d8cdb;
  padding: 20px 15px;
  box-sizing: border-box;
  text-align: center;
}

.neiRightBox {
  width:100%;
  min-height: 500px;
  padding-right: 20px;
  box-sizing: border-box;
}

.neiLeftHotMenu {
  margin-bottom: 10px;
}

.neiLeftHotMenu ul li {
  background: none;
  width: 230px;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  border: 1px solid #dddddd;
  margin-top: 10px;
  font-size: 14px;;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.neiLeftHotMenu ul li:hover {
  border-color: #2d8cdb;
  color: #2d8cdb
}

</style>